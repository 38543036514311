import React from "react";
import '../css/components/particles.css';
import '../css/pages/home.css';
import LandingPage from '../img/landingpage.png'
export default function UpperHomePage(){

    return <div id='uhome' className='wrapper'>
            <div className="homeContainer">
                <div className="homeLeftWrapper">
                    <div className="mainHomeHeading">
                        Boost Mobile App Testing <br/> with App Analytics
                    </div>
                    <div className="mainHomeSubHeading" style={{lineHeight: '1.5'}}>
                            Empowering QA teams and developers with accurate, <br/> efficient, and automated testing solutions.
                    </div>

                    {/* Button */}
                    <div className="getStartedContainer">
                        <a href="/freetrial" target='_blank' rel='noreferrer' style={{textDecoration:'none'}}>
                            <div className="btn btn4" >
                                    Get Started
                            </div>
                        </a>
                    </div>
                </div>
                <div className="homeRightWrapper">
                    <img src={LandingPage} alt="" className="homepageGif" style={{transform:'scale(1.2)'}}/>
                </div>
            </div>
        </div>
};
  