import React,{useState,useCallback,useEffect,useMemo} from 'react';
import '../css/components/topbar.css';
import { getCoords, scrollToDIV } from '../util';
import AppanalyticsLogo from '../img/appanalyticslogo.png';
const Topbar = () => {
    const [lastScrollPosition, setLastScrollPosition] = useState(window.scrollY);
    // const [topbarTopPosition, setTopbarTopPosition] = useState(0);
    // const [underlineLeft, setUnderlineLeft] = useState();
    const [activeTab, setActiveTab] = useState(false);
    const [responsiveMenu, setResponsiveMenu] = useState(false);
    
    const topbarTabs = useMemo(() => [
        {id:0,label:'Upper Home',linkId:'uhome',hide:true},
        {id:1,label:'Home',linkId:'home'},
        {id:2,label:'About',linkId:'about'},
        // {id:2,label:'Portfolio',linkId:'portfolio'},
        {id:3,label:'Features',linkId:'features'},
        {id:4,label:'Solutions',linkId:'solutions'},
        {id:4,label:'New Features',linkId:'newfeatures',anchor:true},
        {id:5,label:'Pricing',linkId:'pricing',anchor:true},
        {id:6,label:'Contact Us',linkId:'contactus'},
        // {id:5,label:'Pricing',linkId:'pricing'},
    ], []);
    const handleNavigation = useCallback(
    e => {
        const window = e.currentTarget;
        setLastScrollPosition(window.scrollY);
        topbarTabs.forEach(element => {
            // var docElementTab = document.getElementById(element.id)
            var docElementPage = document.getElementById(element.linkId)
            // var body = document.body,
            // html = document.documentElement;

            // var documentHeight = Math.max( body.scrollHeight, body.offsetHeight, 
                                // html.clientHeight, html.scrollHeight, html.offsetHeight );
            if(docElementPage){
                // var coordsTab = getCoords(docElementTab);
                var coordsPage = getCoords(docElementPage);
                if(window.scrollY+30>=coordsPage.top){
                    setActiveTab(element.id);
                }
                
                // if(window.scrollY>=coordsPage.top-200 && window.scrollY<(docElementPage.getBoundingClientRect().bottom+window.scrollY-200)){
                //     setUnderlineLeft(coordsTab.left+(window.scrollY/docElementTab.getBoundingClientRect().width))
                // }
            }
        })
    }, [topbarTabs]
    );

    useEffect(() => {
        window.addEventListener("scroll", handleNavigation);

        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);

    const updateUrl = (link) => {
        var url = window.location.href.split(window.location.host)[0]
        url += window.location.host
        console.log(url)
        url = url+"/"+link
        window.history.pushState('App Analytics - Performance Marketing', 'App Analytics - Performance Marketing', url);
 
    }
    return (
        <div className='topbarContainer' style={{background:(lastScrollPosition>50 ? 'white' : 'white'),boxShadow:'0 0 10px 1px rgb(115, 75, 0,0.2)',backdropFilter:(window.scrollY>40 ? 'blur(2px)' : 'none'),color:(window.scrollY>40 ? 'black' : 'white')}}>
            <div style={{width:'30%'}}><img onClick={(e)=>{scrollToDIV('home');updateUrl('home');}} src={AppanalyticsLogo} width={'40%'} alt=''/></div>
            <div style={{display:'flex',width:'70%',alignItems:'center',justifyContent:'flex-end'}}>
                <div className='topbarNavigationMenu'>        
                    {topbarTabs.map((item,index)=>{
                        if(!item.hide){
                            if(item.anchor){
                                return <a href={item.linkId} className={'topbarNavigationMenuTabs'} key={index} style={{textDecoration:"none"}}> 
                                            <div id={item.id} 
                                                        onClick={(e)=>{scrollToDIV(item.linkId);}}
                                                    > 
                                                        <div className='comingsoonItem'>
                                                            <div>{item.label}  </div>
                                                            {/* <div className='comingsoonItemLabel'>{('(Coming Soon)')} </div> */}
                                                        </div>
                                            </div>
                                        </a>
                            }
                            return <div id={item.id} className={activeTab===item.id ? 'topbarNavigationMenuTabs navItemActive' :'topbarNavigationMenuTabs' }
                                        key={index} 
                                        onClick={(e)=>{scrollToDIV(item.linkId);updateUrl(item.linkId);}}
                                    > 
                                    {item.label} 
                                </div>
                        }
                        return null;
                    })}
                </div>
                <div className='topbarNavigationMenuTabs'>
                    <a href='/freetrial' style={{textDecoration:'none'}}>
                        <button className="button-87">Free Trial</button>
                    </a>
                </div>
                <div className='threeDotMenuButton' onClick={()=>{setResponsiveMenu(!responsiveMenu)}}>
                    ...
                </div>
            </div>
            <div className={responsiveMenu ? 'topbarNavigationMobileMenu' : 'topbarNavigationMobileMenuDisplayNone'}>
                {topbarTabs.map((item,index)=>{
                        if(!item.hide){
                            if(item.anchor){
                                return <a href={item.linkId} id={item.id} 
                                            key={index} 
                                            style={{textDecoration:'none'}}
                                            onClick={(e)=>{scrollToDIV(item.linkId);updateUrl(item.linkId);}}
                                        > 
                                            <div className={'topbarNavigationMenuTabs'}>{item.label}  </div>
                                </a>
                            }
                            return <div id={item.id} className={activeTab===item.id ? 'topbarNavigationMenuTabs navItemActive' :'topbarNavigationMenuTabs' }
                                        key={index} 
                                        onClick={(e)=>{scrollToDIV(item.linkId);updateUrl(item.linkId);}}
                                    > 
                                    {item.label} 
                                </div>
                        }
                        return null;
                    })}
            </div>
        </div>
    )
}

export default Topbar